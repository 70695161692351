<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-7 left-side">
            <div class="box-top shadow row">
                <div class="col-sm-8">
                    <div class="row box-top-title col-sm-12">Selamat Datang, &nbsp;<b>{{name}}</b></div>
                    <div class="row box-top-desc col-sm-12">Sistem informasi ini dibangun agar Bimbel Taruna Cendekia semakin maju ke depan. Silahkan hubungi admin jika ada kendala atau kesulitan dalam mengoperasikan.</div>
                </div>
                <div class="col-sm-4" style="text-align-last: end;">
                    <img src="@/assets/img/Taruna-maskot-2.png" class="box-top-img" alt="none">
                </div>
            </div>

            <span style="font-size:18px">Range Tanggal</span>
            <div class="col-md-6 form-group" style="padding:0px;margin-bottom: 12px;margin-top: 6px;">
                <input type="text" class="form-control" ref="daterange">
            </div>

            <div class="shadow" style="width:100%;min-height: 200px;background:white;padding-left: 31px;padding-top: 24px;border-radius: 20px;margin-bottom: 12px;">
                <div class="row">
                    <div class="col-sm-5">
                        <span style="font-size:18px">Ringkasan</span>
                        <div class="row" style="margin-top:12px">
                            <div class="col-sm-5" style="line-height: 2;padding: 0px;padding-left: 7.5px;">
                                Pemasukan
                            </div>
                            <div class="col-sm-7" style="line-height: 2;padding: 0px;padding-left: 7.5px;text-align: right;">
                                <span class="tot_in" style="color:green;font-size: 14px;">Rp 25.000.000.-</span>
                            </div>
                            <div class="col-sm-5" style="line-height: 2;padding: 0px;padding-left: 7.5px;">
                                Pengeluaran
                            </div>
                            <div class="col-sm-7" style="line-height: 2;padding: 0px;padding-left: 7.5px;text-align: right;">
                                <span class="tot_out" style="color:red;font-size: 14px;">Rp 13.500.000.-</span>
                            </div>
                            <div class="col-sm-5" style="line-height: 2;padding: 0px;padding-left: 7.5px;">
                                Laba
                            </div>
                            <div class="col-sm-7" style="line-height: 2;padding: 0px;padding-left: 7.5px;text-align: right;">
                                <span class="tot_all" style="color:green;font-size: 18px;">Rp 11.500.000.-</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <canvas ref="chartbatang" id="siswa-chart" height="200" style="display: block; width: 487px; height: 200px;" width="487" class="chartjs-render-monitor"></canvas>
                    </div>
                </div>
            </div>
            <div class="shadow" style="width:100%;min-height: 262px;background:white;padding-left: 31px;padding-top: 24px;border-radius: 20px;">
                <span style="font-size:18px">Transaction Summary</span>
                <canvas ref="chartscater" id="keaktifan-chart" height="400" style="display: block; width: 487px; height: 400px;margin-top: 12px;" width="487" class="chartjs-render-monitor"></canvas>
            </div>
        </div>
        <div class="col-sm-5" style="padding-left: 24px;">
            <div class="right-side">
                <div class="row" style="margin-bottom:12px">
                    <div class="col-sm-6" style="font-size:18px;align-self: center;">Request Payment</div>
                    <div class="col-sm-6" style="text-align: right;">
                        <router-link to="/pengajuan-biaya">
                        <button class="btn" style="background-image: linear-gradient(#EC008C, #FC6767);color: white;">Lihat Semua</button>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-12 form-group" style="padding: 0px;display: none;">
                    <input id="gender" class="form-control" v-model="form.kbmActivity" type="text" name="i_gender" placeholder="Cari agenda kerja PR" autocomplete="off" />
                </div>
                <div class="row">
                    <div class="col-sm-12" v-for="data in dataPayment">
                        <div class="callout callout-danger">
                            <div class="row">
                                <div class="col-sm-8">
                                  <h5 style="font-size:14px;font-weight:600;color: #7D7D7D;">{{data.category}} {{data.branch}}</h5>
                                  <p style="font-size:14px;font-weight:400;color: #9590A0;">{{data.staff}} | {{data.date}} | <span style="color:black">Rp {{formatMoney(data.nilai_total)}}.-</span></p>
                                </div>
                                <div class="col-sm-4" style="text-align: -webkit-right;">
                                    <div class="event-circle">
                                        <IconifyIcon :icon="icons.activityFill" :style="{fontSize: '42px', color:'white'}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import {auth} from '@/libs/hxcore';
    import IconifyIcon from '@iconify/vue';
    import activityFill from '@iconify/icons-eva/activity-fill';
    import Chart from 'chart.js';
    var chartRingkasan, chartTransaction;
    import Daterangepicker from 'daterangepicker';
    import "daterangepicker/daterangepicker.css";
    import { createTable, authFetch, formatCurrency } from '@/libs/hxcore';
    import $ from 'jquery';
    import moment from "moment";

    export default {
        name: 'KeuanganDashboard',
        components: {
            IconifyIcon
        },
        data() {
            return {
                icons: {
                    activityFill
                },
                form: {
                    kbmActivity : '',
                    dt1: moment().format('YYYY-01-01'),
                    dt2: moment().format('YYYY-MM-DD')
                },
                name : '',
                imgUrl : '',
                dataPayment: []
            };
        },
        created: function() {
            this.roles = this.$route.meta.roles;
            var info = auth.user();
            // console.log('user', info)
            if(info)
            {
              this.name = info.display_name;
              this.imgUrl = info.img_url;
            }
            else{
              this.imgUrl = '/dist/img/user.jpeg';
            }
        },
        methods: {
            formatMoney(data) {
                return formatCurrency(data);
            },
            formatDate(data) {
                return moment(data).format('DD/MM/YYYY')
            },
            loadData() {
                var self = this;
                authFetch('/keuangan/keuangan_pusat/dashboard_data?date_start='+self.form.dt1+'&date_end='+self.form.dt2)
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        // this.optBranch = js.data;
                        // console.log(js)
                        $('.tot_in').html("Rp "+formatCurrency(js.data.tot_in))
                        $('.tot_out').html("Rp "+formatCurrency(js.data.tot_out))
                        $('.tot_all').html("Rp "+formatCurrency(js.data.tot_all))
                        if (parseFloat(js.data.tot_all) < 0) {
                            $('.tot_all').attr('style', 'color:red;font-size: 18px;')
                        } else {
                            $('.tot_all').attr('style', 'color:green;font-size: 18px;')
                        }
                        chartRingkasan.data.labels = js.data.labels
                        chartRingkasan.data.datasets = [
                            {
                                label: "Pemasukan",
                                backgroundColor: "#1AD587",
                                borderColor: "#1AD587",
                                data: js.data.data_in,
                                fill: false,
                            },
                            {
                                label: "Pengeluaran",
                                backgroundColor: "#FF4069",
                                borderColor: "#FF4069",
                                data: js.data.data_out,
                                fill: false,
                            },
                        ]
                        chartRingkasan.update()

                        chartTransaction.data.labels = js.data.labels
                        chartTransaction.data.datasets = [
                            // {
                            //   label: 'Transfer',
                            //   backgroundColor: "#caf270",
                            //   data: [12, 59, 5, 56, 58,12],
                            // }, 
                            {
                              label: 'Pengeluaran',
                              backgroundColor: "#45c490",
                              data: js.data.counter_out,
                              borderRadius: Number.MAX_VALUE,
                            }, {
                              label: 'Penerimaan',
                              backgroundColor: "#008d93",
                              data: js.data.counter_in,
                              borderRadius: Number.MAX_VALUE,
                            }
                        ]
                        chartTransaction.update()
                    })
            },
            loadPayment() {
                var self = this;
                authFetch('/keuangan/keuangan_pusat/dashboard_data_payment')
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        // console.log(js)
                        self.dataPayment = js.data
                    })
            }
        },
        mounted() {
            var self = this;
            new Daterangepicker(this.$refs.daterange, {
                startDate: moment().startOf('year'),
                endDate: moment(),
            }, function(d1, d2){
                self.form.dt1 = d1.format('YYYY-01-01');
                self.form.dt2 = d2.format('YYYY-MM-DD');
                self.loadData()
            });
            self.loadData()
            self.loadPayment()

            var ctx = document.getElementById('siswa-chart').getContext('2d')

            chartRingkasan = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April"
                    ],
                    datasets: [
                      {
                        label: "Pemasukan",
                        backgroundColor: "#1AD587",
                        borderColor: "#1AD587",
                        data: [40, 20, 12, 39],
                        fill: false,
                      },
                      {
                        label: "Pengeluaran",
                        backgroundColor: "#FF4069",
                        borderColor: "#FF4069",
                        data: [30, 12, 24, 31],
                        fill: false,
                      },
                    ]
                  },
                options: {
                    responsive: true,
                    legend: {
                        position: 'top',
                        display: false
                    },
                    title: {
                        display: false,

                    }
                }
            });

            var ctx3 = document.getElementById('keaktifan-chart').getContext('2d')
            Chart.helpers.merge(Chart.defaults.global, {
                datasets: {
                    roundedBar: {
                        categoryPercentage: 0.8,
                        barPercentage: 0.9
                    }
                }
            });
            chartTransaction = new Chart(ctx3, {
                type: 'bar',
                data: {
                    labels: ["Mon","Tue","Wed","Thu","Fri","Sun"],
                    datasets: [
                    // {
                    //   label: 'Transfer',
                    //   backgroundColor: "#caf270",
                    //   data: [12, 59, 5, 56, 58,12],
                    // }, 
                    {
                      label: 'Pengeluaran',
                      backgroundColor: "#45c490",
                      data: [12, 59, 5, 56, 58,12],
                      borderRadius: Number.MAX_VALUE,
                    }, {
                      label: 'Penerimaan',
                      backgroundColor: "#008d93",
                      data: [12, 59, 5, 56, 58,12],
                      borderRadius: Number.MAX_VALUE,
                    }],
                  },
                options: {
                    tooltips: {
                      displayColors: true,
                      callbacks:{
                        mode: 'x',
                      },
                    },
                    scales: {
                      xAxes: [{
                        stacked: true,
                        gridLines: {
                          display: false,
                        }
                      }],
                      yAxes: [{
                        stacked: true,
                        ticks: {
                          beginAtZero: true,
                        },
                        type: 'linear',
                      }]
                    },
                    responsive: true,
                    // maintainAspectRatio: false,
                    legend: { position: 'bottom' },
                }
            });
        }
    }
</script>

<style type="text/css" scoped>
    .box-top {
        min-height: 141px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 16px;
        padding-bottom: 12px;
        padding-top: 12px;
    }

    .box-top-title {
        padding-top: 17px;
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-size: 24px;
    }

    .box-top-desc {
        padding-left: 31px;
        min-height: 57px;
        align-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #7D7D7D;
    }

    .shadow {
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 4%) !important;
    }

    .custom-container {
        padding: 31px 31px 0px 31px;
        height: 100%;
    }

    .items {
        padding-left: 32px;
        padding-top: 25px;
        margin-left: 12px;
        margin-bottom: 21px;
        min-width: 220px;
        background-color: white;
        border-radius: 20px;
        min-height: 210px;
    }

    .items-circle {
        width: 76px;
        height: 76px;
        background: linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%);
        border-radius: 50%;
        margin-bottom: 12px;
        text-align: center;
    }

    .items-title {
        height: 40px;
        padding-top : 4px;
        padding-bottom : 4px;
        font-weight: 700;
        font-size: 24px;
        color: #363636;
    }

    .items-desc {
        padding-right: 30px;
    }

    .items-icon {
        transform: translateY(50%);
        -ms-transform: translateY(50%);
    }

    .box-top-img {
    }

    /*right side*/
    .right-side {
        background: white;
        border-radius: 10px;
        padding: 26px 19px 0px 22px;
        height: 100%;
    }

    #myCal {
        margin-top: 25px;
        margin-bottom: 65px;
        width: 100%;
        text-align-last: center;
    }

    .event-circle {
        width:48px;
        height: 48px;
        background: #C8372D;
        border-radius: 50%;
    }

    .callout-danger {
        background: #EBEBEB;
    }

    .callout.callout-danger {
        border-left: 8px #C8372D solid;
        border-radius: 10px;
    }

    .callout-warning {
        background: #EBEBEB;
    }

    .callout.callout-warning {
        border-left: 8px #FCA702 solid;
        border-radius: 10px;
    }

    .callout-info {
        background: #EBEBEB;
    }

    .callout.callout-info {
        border-left: 8px #1FA2FF solid;
        border-radius: 10px;
    }

    .color-calendar {
        font-size: 0.7rem;
    }
</style>